import React from "react";
import diseaseManagement from "../../Assets/Images/blg_liver.png";

const Blog_NAFLD = () => {
  const researchPapers = [
    {
      title:
        "The role of nutrition in non-alcoholic fatty liver disease: Pathophysiology and management",
      authors: "Genoveva Berná, Manuel Romero-Gomez",
      date: "20 February 2020",
      doi: "https://doi.org/10.1111/liv.14360",
    },
    {
      title: "A Comprehensive Review on Non-Alcoholic Fatty Liver Disease",
      authors: "Cureus",
      date: "2023 Dec 8",
      doi: "https://doi.org/10.7759/cureus.50159",
    },
    {
      title:
        "Nutritional Approaches for the Management of Nonalcoholic Fatty Liver Disease: An Evidence-Based Review",
      authors: "Nutrients",
      date: "2020 Dec 17",
      doi: "https://doi.org/10.3390/nu12123860",
    },
    {
      title:
        "Dietary strategies in non-alcoholic fatty liver disease patients: From evidence to daily clinical practice, a systematic review",
      authors: "United European Gastroenterol J",
      date: "2023 Jul 25",
      doi: "https://doi.org/10.1002/ueg2.12443",
    },
  ];

  return (
    <div className="blog-template clearfix">
      <header className="blog-header">
        <h1
          style={{ textTransform: "capitalize" }}
          className="blog-title"
          id="nafld"
        >
          Non-alcoholic fatty liver disease (NAFLD)
        </h1>
        {/* <p className="blog-date">October 01, 2024</p> */}
        <p className="blog-keywords">Nutrition, Health, Wellness</p>
      </header>

      {/* <img
        src={diseaseManagement}
        alt="Nutrition-related"
        className="blog-image"
      /> */}

      <div className="blog-content">
        <img
          src={diseaseManagement}
          alt="Nutrition-related"
          className="blog-image"
        />
        <section className="diabetes-overview">
          <h2>What is NAFLD?</h2>
          <p>
            Non-alcoholic fatty liver disease is a condition where
            excess fat builds up in the liver. (NAFLD) is a global health
            concern, affecting around one-quarter of people worldwide. Obesity,
            diabetes, dyslipidemia, insulin resistance, and metabolic syndrome
            are known to be associated with the development of non-alcoholic
            fatty liver disease (NAFLD). While many individuals with NAFLD may
            not experience symptoms or complications and it can progress to
            non-alcoholic steatohepatitis (NASH), which involves liver
            inflammation and can lead to more severe liver damage, such as
            fibrosis or cirrhosis.
          </p>
          <p>
            Non-alcoholic fatty liver disease (NAFLD) can be caused by a various
            factors including:
          </p>
          <ul className="NAFLD-list">
            <li>
              <strong>Obesity:</strong> Excess body weight is a major risk
              factor, particularly central obesity (fat accumulation around the
              abdomen).{" "}
            </li>
            <li>
              <strong>Type 2 Diabetes:</strong> Insulin resistance and high
              blood sugar levels can contribute to fat buildup in the liver.{" "}
            </li>
            <li>
              <strong>Dyslipidemia:</strong> Abnormal levels of lipids in the
              blood, such as high triglycerides and low HDL cholesterol, can
              increase the risk.{" "}
            </li>
            <li>
              <strong>Metabolic Syndrome:</strong> A cluster of conditions,
              including hypertension, high blood sugar, excess body fat, and
              abnormal cholesterol levels, increases the risk of NAFLD.{" "}
            </li>
            <li>
              <strong>Diet:</strong> Diets high in saturated fats, sugars
              (especially fructose), and refined carbohydrates, and low in fibre
              can lead to NAFLD.{" "}
            </li>
            <li>
              <strong>Sedentary Lifestyle:</strong> Lack of physical activity
              can contribute to weight gain and metabolic issues.
            </li>
          </ul>

          <h2>Management</h2>
          <p>
            Lifestyle changes are recommended for all patients with
            non-alcoholic fatty liver disease (NAFLD). Physical exercise, a
            balanced diet, and weight loss are crucial components of managing
            non-alcoholic fatty liver disease (NAFLD).
          </p>
          <ul className="NAFLD-list">
            <li>
              <strong>Physical Exercise:</strong> Regular physical activity
              helps improve insulin sensitivity, promotes weight loss, and
              reduces liver fat. Both aerobic exercises (like walking, running,
              or cycling) and resistance training can be beneficial.{" "}
            </li>
            <li>
              <strong>Diet:</strong> A diet low in refined carbohydrates,
              sugars, and saturated fats—while rich in whole grains, fruits,
              vegetables, lean proteins, and healthy fats—can significantly
              improve liver health.{" "}
            </li>
            <li>
              <strong>Weight Loss:</strong> Reducing caloric intake by 500-1000
              kcal per day can help achieve sustainable weight loss, which is
              associated with improved insulin sensitivity and reduced hepatic
              steatosis (fat accumulation in the liver).{" "}
            </li>
          </ul>

          <p>
            Studies show that even a modest weight loss of 5-10% of body weight
            can lead to significant improvements in liver health for those with
            NAFLD. These lifestyle changes not only help in managing NAFLD but
            also improve overall metabolic health.
          </p>
          <p>
            Here are some key dietary recommendations can help improve liver
            health and reduce the risk of progression of NAFLD.
          </p>
          <ul className="NAFLD-list">
            <li>
              <strong>Limit excess fructose consumption:</strong> Reduce intake
              of sugary beverages such as sodas, packed fruit juices, fruit
              cocktails, sweets, and processed snacks, jam, jelly, flavoured
              &sweetened milk, packed foods with added sugar like breakfast
              cereals and ready to eat foods
            </li>
            <li>
              <strong>Focus on Whole Foods:</strong> Incorporate plenty of
              fruits and vegetables, which are rich in antioxidants and Fiber.
              Choose whole grains like brown rice, quinoa, and whole grain bread
              over refined grains.{" "}
            </li>
            <li>
              <strong>Healthy Fats:</strong> Include sources of healthy fats,
              such as avocados, nuts, seeds, and olive oil, while limiting
              saturated and trans fats found in fried and processed foods.{" "}
            </li>
            <li>
              <strong>Lean Proteins:</strong> Opt for lean protein sources like
              chicken, fish, legumes, and plant-based proteins. Limit red and
              processed meats.{" "}
            </li>
            <li>
              <strong>Control Portions:</strong> Be mindful of portion sizes to
              help manage caloric intake and promote weight loss if needed.{" "}
            </li>
            <li>
              <strong>Stay Hydrated:</strong> Drink plenty of water throughout
              the day, and limit consumption of sugary drinks and alcohol.
            </li>
            <li>
              <strong>Avoid excess alcohol consumption:</strong>{" "}
            </li>
            <li>
              <strong>Consider Omega-3 Fatty Acids:</strong> Incorporate fatty
              fish (like salmon, mackerel, and sardines) which are high in
              omega-3 fatty acids, known to have anti-inflammatory effects.{" "}
            </li>
            <li>
              <strong>Seek Professional Guidance:</strong> Work with registered
              dietitian to create a personalize meal plan that fits your needs
              and preferences.{" "}
            </li>
          </ul>
        </section>

        <section className="conclusion">
          <h2>Sources: </h2>
          <ul className="NAFLD-list">
            {researchPapers.map((paper, index) => (
              <li key={`research-${index}`}>
                <span
                  style={{ fontStyle: "italic" }}
                  id={`research-title-${index}`}
                >
                  {paper.title}
                </span>
                , {paper.authors}, {paper.date},
                <a href={paper.doi} target="_blank" rel="noopener noreferrer">
                  {paper.doi}
                </a>
              </li>
            ))}
          </ul>
        </section>

        {/* <footer className="blog-footer">
          <h3>Share this post:</h3>
           <div className="social-media-links">
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
            <a
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            <a
              href="https://www.linkedin.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </div>
        </footer> */}
      </div>
    </div>
  );
};

export default Blog_NAFLD;
