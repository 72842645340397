import React from "react";
import diseaseManagement from "../../Assets/Images/blog_Impact.png";

const Blog_BalancedDiet = () => {
  return (
    <div className="blog-template clearfix">
      <header className="blog-header">
        <h1
          style={{ textTransform: "capitalize" }}
          className="blog-title"
          id="blog-balanced-diet"
        >
          The impact of food on our health{" "}
        </h1>
        {/* <p className="blog-date">October 01, 2024</p> */}
        <p className="blog-keywords">Nutrition, Health, Wellness</p>
      </header>

      <div className="blog-content">
        <img
          src={diseaseManagement}
          alt="Nutrition-related"
          className="blog-image"
        />
        <div className="diabetes-overview">
          <p>
            Food is a basic necessity of life. It helps us stay healthy and
            active. Unhealthy eating habits such as overeating, high sugar
            Intake, excessive processed foods, skipping meals, excessive salt
            intake, Diets lacking in fruits & vegetables, late-night eating,
            poor food choices are a significant contributor to lifestyle
            diseases such as Obesity, Diabetes, Hypertension and Heart diseases.
          </p>
          <p>
            In the current scenario, many people follow different fad diets
            without proper guidance often failing to realize whether these diet
            plans are healthy, well-balanced, and sustainable. Fad diets can
            often be misleading and may not provide the necessary nutrients our
            bodies need. Eating a balanced and healthy diet is foundational to
            good health. This understanding is essential in a world brimming
            with diet trends and conflicting nutrition advice.
          </p>
          <h2>What is Balanced diet?</h2>
          <p>
            A balanced diet is the cornerstone of health. It involves consuming
            a variety of foods in the right proportions so that the requirement
            for calories, proteins, minerals, vitamins are adequate. A balanced
            diet should comprise 60-70% of total calories from carbohydrates,
            10-15% from proteins and 20-25% of total calories from fat. This
            balance is key to providing the body with the essential nutrients it
            needs to function effectively.{" "}
          </p>

          <h2>How important is it to eat a balanced diet to stay healthy? </h2>
          <p>
            A healthy lifestyle can be procured by maintaining a balanced diet
            and keeping it in mind to meet all the essential nutrients needed by
            the body. There are six types of nutrients essential for survival.
            Carbohydrates, proteins, lipids (fats), vitamins, minerals and
            water. Carbohydrates, Proteins and fats are macronutrients, ‘macro-’
            meaning we require them in large amounts. These provide the body
            with energy. Vitamins and minerals are classed as micronutrients
            because they are only required in small amounts. Water is also an
            essential nutrient.{" "}
          </p>
          <p>
            Our bodies require all the nutrients in adequate amounts to function
            properly and maintain health. A well-balanced diet ensures that we
            receive the necessary macronutrients (carbohydrates, proteins, and
            fats) and micronutrients (vitamins and minerals) that support bodily
            functions and promote cell health.
          </p>
          <p>
            A balanced diet should include the following five basic food groups:{" "}
          </p>
          <ul>
            <li>
              <strong>Carbohydrates:</strong> The primary source of energy,
              found in foods like pasta, bread, rice, and grains. They help
              maintain energy levels throughout the day.
            </li>
            <li>
              <strong>Proteins:</strong> Essential for building and repairing
              tissues. Sources include meat, beans, and eggs, which support
              muscle health and growth.
            </li>
            <li>
              <strong>Milk & Milk Products:</strong> Milk products are the best
              sources of calcium. It helps promote strong bones and healthy
              teeth .They supply protein, riboflavin, and vitamins A and D (if
              fortified).People who cannot drink milk can choose lactose-free
              milk or plain soy milk.
            </li>
            <li>
              <strong>Fruits and Vegetables:</strong> Packed with vitamins,
              minerals, and fibre, they support immune function and digestion,
              making them vital for overall health.
            </li>
            <li>
              <strong>Fats and Oils: </strong>Necessary for energy and various
              bodily functions. Healthy sources include avocados, nuts, seeds,
              oil, but they should be consumed in moderation.
            </li>
          </ul>

          <p>
            There is no one food that can provide all the nutrients your body
            needs. Choosing a variety of foods within each food group will help
            you meet your nutritional requirements. It will also make your diet
            more interesting. Each food group provides certain nutritional
            benefits, so foods from each group should be consumed each day.
          </p>

          <h2>Steps to stay healthy </h2>
          <ul>
            <li>
              <strong>Practice Mindful Eating:</strong> Pay attention to your
              hunger cues and eat slowly to enjoy your food and stop when you’re
              full.{" "}
            </li>
            <li>
              <strong>Healthy Snacking:</strong> Swap out processed snacks for
              options like boiled pulses, fruits, veggies, and nuts.{" "}
            </li>
            <li>
              <strong>Stay Hydrated:</strong> Drink plenty of water; try to
              limit sugary and carbonated beverages.{" "}
            </li>
            <li>
              <strong>Balanced Diet:</strong> Incorporate a variety of foods
              from all five food groups and minimize sweets and baked goods.{" "}
            </li>
            <li>
              <strong>Plan Your Meals:</strong> Prepare healthy meals in advance
              to avoid last-minute unhealthy choices.{" "}
            </li>
            <li>
              <strong>Watch your weight:</strong> Exercise Regularly and Be
              Physically Active{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Blog_BalancedDiet;
