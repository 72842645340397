import React, { useEffect } from "react";
import "../Assets/Css/ContactMe.css";
import TagCloud from "TagCloud";
import contactUs from "../Assets/Images/contactUs.jpeg";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { SiWhatsapp } from "react-icons/si";
import backgroundImage from "../Assets/Images/backgBott.png";
const ContactUs = () => {
  useEffect(() => {
    const container = ".tagcloud";
    const texts = [
      "Special Diet",
      "Weight Management",
      "Disease Management",
      "🍅",
      "🥦",
      "🥗",
      "🥕",
    ];

    const options = {
      radius: 200,
      maxSpeed: "fast",
      initSpeed: "fast",
      keep: true,
    };

    const tagCloudInstance = TagCloud(container, texts, options);

    return () => {
      tagCloudInstance.destroy();
    };
  }, []);

  const handleWhatsAppCall = () => {
    const phoneNumber = "+919742044422";
    const message = "Hello, I would like to inquire...";
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleEmailClick = () => {
    const subject = "Inquiry from Website";
    const body = "Hello, I would like to know more about...";
    window.location.href = `mailto:letsdietnow247@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };

  const clearForm = () => {
    document.getElementById("name").value = "";
    document.getElementById("phone").value = "";
    document.getElementById("email").value = "";
    document.getElementById("message").value = "";
  };

  const handleSubmit = (e) => {
    debugger;
    e.preventDefault();
    try {
      const name = document.getElementById("name").value;
      const phone = document.getElementById("phone").value;
      const email = document.getElementById("email").value;
      const message = document.getElementById("message").value;

      if (!name || !phone || !email || !message) {
        alert("All fields are required.");
        return;
      }

      const preFilledMessage = `Name: ${name}\nPhone: ${phone}\nEmail: ${email}\nMessage: ${message}`;
      const whatsappUrl = `https://wa.me/919742044422?text=${encodeURIComponent(
        preFilledMessage
      )}`;

      window.open(whatsappUrl, "_blank");

      alert("Form submission successful");
      clearForm();
    } catch (error) {
      console.log(error);
      alert("Form submission failed. Please fill all the fields.");
    }
  };

  return (
    <>
      <div className="contact-me">
        {/* <
         src={contactUs} alt="contact Me" /> */}

        <div
          className="text-sphere"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <span className="tagcloud"></span>
        </div>
      </div>
      <div className="contactMe-container">
        <div
          className="contact-info"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
         <div className="address">
            <FaMapMarkerAlt className="icon" />
            <div>
              <h3>Emmany D R</h3>
              <p>
                07 unit 116, <br />
                Blk 153,<br />
                Rivervale crescent, <br />
                Singapore - 540153.
              </p>
            </div>
          </div>
          <div
            className="mobile"
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
            onClick={handleWhatsAppCall}
          >
            <SiWhatsapp className="icon" />
            <div>
              <h3>Whatsapp</h3>
              <p>+91 9742044422</p>
            </div>
          </div>
          <div
            className="email"
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
            onClick={handleEmailClick}
          >
            <div className="icon">
              <FaEnvelope />
            </div>
            <div>
              <h3>Email</h3>
              <p>letsdietnow247@gmail.com</p>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <h2>Get in touch</h2>
          <p>Don't hesitate to message us, Our team will help you.</p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              required
            />
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="Phone"
              required
            />
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              required
            />
            <textarea
              id="message"
              name="message"
              placeholder="Message"
              rows="5"
              required
            ></textarea>
            <div className="contact-me-sub-btn">
              <button className="button" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
