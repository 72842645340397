import React from "react";
import "../Assets/Css/AboutMe.css";
import DtEmmany from "../Assets/Images/EmmaBK.png";
import { RiDoubleQuotesL } from "react-icons/ri";
import { RiDoubleQuotesR } from "react-icons/ri";
import { BiFontFamily } from "react-icons/bi";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import backgroundImage from "../Assets/Images/backgBott.png";

const AboutUs = () => {
  return (
    <div className="aboutMe-container">
      <div className="about-ldn">
        <div className="img-para">
          <div>
            <img src={DtEmmany} alt="DtEmmany" />
          </div>
          <div className="aboutMe-ldn-para">
            <h1 style={{ fontFamily: "'Edu AU VIC WA NT Hand', sans-serif" }}>
              I’m Emmany Sam!
            </h1>
            <p>
              {" "}
              Registered Dietitian with 14 years of clinical experience and
              specialized in managing various disease conditions like Diabetes
              Mellitus, Fatty liver Disease, Kidney Disease , Hypertension, High
              Cholesterol, Weight Management, IBS and
              Ketogenic diet for Epilepsy patients{" "}
            </p>
            {/* <p>Founder -Let's Diet Now</p> */}

            {/* <ul style={{ listStyle: "none", padding: 0 }}>
              <li>
                <CheckCircleIcon
                  sx={{ color: "#385537", marginRight: "8px" }}
                />{" "}
                Registered Dietitian
              </li>
              <li>
                <CheckCircleIcon
                  sx={{ color: "#385537", marginRight: "8px" }}
                />{" "}
                14 Years of Clinical Experience
              </li>
              <li>
                <CheckCircleIcon
                  sx={{ color: "#385537", marginRight: "8px" }}
                />{" "}
                Certification in Ketogenic Diet Therapy
              </li>
            </ul> */}
          </div>
        </div>
      </div>
      <div className="about-me">
        <h3>
          <span>
            <RiDoubleQuotesL className="quote-icon" />
            Food is meant to be enjoyed, Lets balance it at Let's Diet Now
            <RiDoubleQuotesR className="quote-icon" />
          </span>
        </h3>
      </div>

      <div className="know-me"></div>
      <div className="about-me-expertise">
        <div className="expertise">
          <h5>Qualifications:</h5>
          <div className="expertise-grid">
            <div className="grid-item">
              <i class="fas fa-user-graduate"></i>
              <p>M.Sc. (DFSM)</p>
            </div>
            <div className="grid-item">
              <i class="fas fa-graduation-cap"></i>
              <p>PG Dip in Dietetics</p>
            </div>
            <div className="grid-item">
              <i class="fas fa-award"></i>
              <p>Certification in Ketogenic Diet Therapy</p>
            </div>
          </div>
        </div>
        <div
          className="experience"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            height: "auto",
            width: "auto",
          }}
        >
          <h5>Membership of Professional Societies:</h5>
          <div className="expertise-grid">
            <div className="grid-item">
              <i class="fa-solid fa-users"></i>
              <p>A Lifetime member of IDA (Indian Dietetic Association)</p>
            </div>
            <div className="grid-item">
              <i class="fa-solid fa-notes-medical"></i>
              <p>
                A Lifetime member of IAPEN (India Association for Parenteral and
                Enteral Nutrition)
              </p>
            </div>
            <div className="grid-item">
              <i class="fa-solid fa-hands-helping"></i>
              <p>A Member of the Singapore Dietetic Association</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="ab-me">
        <h3>
          <span>
            <RiDoubleQuotesL className="quote-icon" />
            Expertise combined with personal commitment — that’s my recipe for
            success at Lets Diet Now.
            <RiDoubleQuotesR className="quote-icon" />
          </span>
        </h3>
      </div> */}
    </div>
  );
};

export default AboutUs;
