import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import ContactUs from "./Pages/ContactUs";
import Bookings from "./Pages/Bookings";
import Services from "./Pages/Services";
import AboutUs from "./Pages/AboutUs";
import NutritionPlan from "./Pages/NutritionPlan";
import Blog_BalancedDiet from "./Pages/Blogs/Blog_BalancedDiet";
import Blog_NAFLD from "./Pages/Blogs/Blog_NAFLD";
import Term from "./Pages/Terms";
import blockInspect from "./Components/BlockInspect";
import BlogTemplate from "../src/Pages/Blogs/Blog_Diabetes";

function App() {
   useEffect(() => {
     blockInspect();
   }, []);
  return (
    <>
      <Router>
        <div>
          <NavBar />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/booking" element={<Bookings />} />
              <Route path="/letsdietnow-services" element={<NutritionPlan />} />
              <Route path="/terms" element={<Term />} />
              <Route path="/blogs/diabetes" element={<BlogTemplate />} />
              <Route
                path="/blogs/balanced diet"
                element={<Blog_BalancedDiet />}
              />
              <Route
                path="/blogs/non alcholic fatty liver disease"
                element={<Blog_NAFLD />}
              />
            </Routes>
          </main>
          <Footer />

          <a
            href="https://wa.me/919742044422"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp fontSize="40px" />
          </a>
        </div>
      </Router>
    </>
  );
}

export default App;
