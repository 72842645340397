import React, { useEffect, useState, useRef } from "react";
import "../Assets/Css/NutritionPlan.css";
import { useLocation } from "react-router-dom";
import dummyImage from "../Assets/Images/dummyImage.jpg";
import backgroundImage from "../Assets/Images/backgBott.png";

const NutritionPlan = () => {
  const { hash } = useLocation();
  const location = useLocation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    age: "",
    height: "",
    weight: "",
    area: "",
    state: "",
    country: "",
    consultationFor: "",
    dateTime: "",
  });

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const selectedService = params.get("service");
    if (selectedService) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        consultationFor: selectedService,
      }));
    }
  }, [location]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const consultationOptions = {
    "weight-management": "Weight Management",
    diabetes: "Type I and Type II Diabetes",
    gestationDiabetes: "Prediabetes",
    chronicKidneyDisease: "Chronic Kidney Disease",
    fattyLiverDisease: "Fatty Liver Disease",
    hyperLipidemia: "Hypeylipidemia",
    hypertension: "Hypertension",
    irritableBowelSyndrome: "Irritable Bowel Syndrome",
    //crohnDisease: "Crohn's Disease",
    pcos: "PCOS",
    paediatricNutrition: "Paediatric Nutrition",
    ketogenicDiet: "Ketogenic Diet",
    //cancerDiet: "Diet for Cancer Treatment",
    other:"Others"
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      name,
      email,
      phone,
      dateTime,
      age,
      height,
      weight,
      area,
      state,
      country,
      consultationFor,
    } = formData;

    const consultationLabel =
      consultationOptions[consultationFor] || consultationFor;

    const message = `
      Consultation Request:
      
      Consultation For: ${consultationLabel}
      Name: ${name}
      Email: ${email}
      Phone: ${phone}
      Age: ${age}
      Height: ${height}
      Weight: ${weight}
      Area: ${area}
      State: ${state}
      Country: ${country}
      Preferred Date and Time: ${dateTime}
    `;

    const whatsappLink = `https://wa.me/9742044422?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappLink, "_blank");
  };

  return (
    <div style={{
      backgroundImage: `url(${backgroundImage})`}}>
      <div className=" nutri-plan-container" id="our-services" >
        {/* <h1>LetsDietNow Services</h1>
        <p>
          LetsDietNow offers personalized services for diabetes and disease
          management, providing tailored meal plans to manage blood sugar
          levels. The app helps you for tracking diet, glucose, and health
          metrics, along with educational resources. Users can also connect with
          certified nutritionists for guidance, promoting a holistic approach to
          health management.
        </p> */}

        {/* <div className="services-offered">
          <div className="weight_management" id="weight-management">
            <h3>Weight Management</h3>
            <div className="services-offered-content-wrapper">
              <img src={dummyImage} alt="dummy image" />
              <div className="services-offered-content">
                <p>
                  You want to drop weight and want to do it safely? “Lose weight
                  the right way”. Weight management refers to the process of
                  achieving and maintaining healthy weight through combination
                  of balanced diet, exercise and life style changes. It’s
                  important to focus on progress, not perfection. We focus on
                  customised diet plan to help you achieve and maintain a
                  healthy weight.
                </p>
              </div>
            </div>
            <button onClick={() => handleConsultClick("Weight Management")}>
              Consult Nutritionist
            </button>
          </div>
          <div className="diabetes" id="diabetes">
            <h3>Diabetes</h3>
            <div className="services-offered-content-wrapper">
              <img src={dummyImage} alt="dummy image" />
              <div className="services-offered-content">
                <p>
                  <b>Type I & Type II Diabetes:</b> Managing diabetes doesn’t
                  have to be complicated, especially with convenient and
                  accessible nutrition counselling, “Get the personalised diet
                  plans you need and manage your blood sugar levels”
                </p>
                <p>
                  <b>Pre-Diabetes:</b> Pre diabetes is a warning of Type 2
                  Diabetes.it means blood sugar levels are higher than normal
                  but not high enough to be considered Type 2 diabetes. Adopting
                  a healthier diet, regular exercise and maintain a healthy
                  weight can reverse Pre diabetes.” Get personalized diet plan
                  and delay progression to Type 2 diabetes”
                </p>
              </div>
            </div>
            <button onClick={() => handleConsultClick("Diabetes")}>
              Consult Nutritionist
            </button>
          </div>
          <div className="chronic-kidney" id="chronic-kidney-failure">
            <h3>Chronic Kidney Disease</h3>
            <div className="services-offered-content-wrapper">
              <img src={dummyImage} alt="dummy image" />
              <div className="services-offered-content">
                <p>
                  When you have kidney problems, Diet becomes more important
                  than ever. Our kidney removes toxins from our bodies. When you
                  have chronic kidney disease, your body’s ability to remove
                  waste is compromised. Making conscious food choices and having
                  a well-balanced diet becomes an important part in managing
                  your disease progression. “Eat right for chronic kidney
                  disease”. Get personalized diet plan and delay progression of
                  chronic kidney disease
                </p>
              </div>
            </div>
            <button
              onClick={() => handleConsultClick("Chronic Kidney Disease")}
            >
              Consult Nutritionist
            </button>
          </div>
          <div className="fatty-liver" id="fatty-liver-disease">
            <h3>Fatty Liver Disease</h3>
            <div className="services-offered-content-wrapper">
              <img src={dummyImage} alt="dummy image" />
              <div className="services-offered-content">
                <p>
                  Fatty liver disease is a condition where the liver cells
                  become filled with fat. This fat can cause injury to the liver
                  which can permanently scarred and damage the liver. You can
                  control the progression of fatty liver by making the choice to
                  change your diet. “A Healthy Diet, a Healthier liver”
                </p>
              </div>
            </div>
            <button onClick={() => handleConsultClick("Fatty Liver Disease")}>
              Consult Nutritionist
            </button>
          </div>
          <div className="Hyperlipidaemia" id="hyperlipidemia">
            <h3>Hyperlipidaemia</h3>
            <div className="services-offered-content-wrapper">
              <img src={dummyImage} alt="dummy image" />
              <div className="services-offered-content">
                <p>
                  What you eat can affect the amount of cholesterol circulating
                  in your blood. People with high cholesterol have twice the
                  risk of developing heart disease, the leading cause of death.
                  Take a control of your heart health today and embark on a
                  journey towards a healthier happier you! Learn how to improve
                  your heart health and lower your cholesterol by following our
                  customized diet plan.
                </p>
              </div>
            </div>
            <button onClick={() => handleConsultClick("Hyperlipidaemia")}>
              Consult Nutritionist
            </button>
          </div>
          <div className="Hypertension" id="hypertension">
            <h3>Hypertension</h3>
            <div className="services-offered-content-wrapper">
              <img src={dummyImage} alt="dummy image" />
              <div className="services-offered-content">
                <p>
                  High blood pressure is called the “silent killer” because it
                  can go undetected for years. Eating a heart healthy diet is
                  important for managing your blood pressure and reducing your
                  risk of heart attack, stroke and other health threats. Making
                  changes to your diet is a proven way to help control high
                  blood pressure. We focus on quality Diet plan to manage your
                  high blood pressure.
                </p>
              </div>
            </div>
            <button onClick={() => handleConsultClick("Hypertension")}>
              Consult Nutritionist
            </button>
          </div>
          <div className="PCOs" id="pcos">
            <h3>Polycystic ovary syndrome (PCOS)</h3>
            <div className="services-offered-content-wrapper">
              <img src={dummyImage} alt="dummy image" />
              <div className="services-offered-content">
                <p>
                  Polycystic ovary syndrome (PCOS) is a common complex hormonal
                  condition which affects how the ovaries work and it affects up
                  to 20 per cent of women worldwide, yet the exact cause is
                  still unknown. Studies show that what you eat, along with
                  daily physical activity, makes a significant impact on
                  managing symptoms. We focus on balanced diet plan to help&
                  improve PCOS symptoms.
                </p>
              </div>
            </div>
            <button
              onClick={() =>
                handleConsultClick("Polycystic ovary syndrome (PCOS)")
              }
            >
              Consult Nutritionist
            </button>
          </div>
        </div> */}

        <div className="booking-form" >
          <h3>Book an Appointment</h3>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <input
              type="number"
              name="age"
              placeholder="Age"
              value={formData.age}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="height"
              placeholder="Height (e.g., 5'6 or 170 cm)"
              value={formData.height}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="weight"
              placeholder="Weight (e.g., 70 kg)"
              value={formData.weight}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="area"
              placeholder="Area"
              value={formData.area}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              value={formData.state}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="country"
              placeholder="Country"
              value={formData.country}
              onChange={handleChange}
              required
            />

            {/* Prefilled consultationFor field */}
            <select
              name="consultationFor"
              value={formData.consultationFor}
              onChange={handleChange}
              required
            >
              {Object.entries(consultationOptions).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>

            <input
              type="text"
              name="dateTime"
              placeholder="Preferred Date and Time"
              value={formData.dateTime}
              onChange={handleChange}
            />

            <button className="form-button" type="submit">
              Book Now
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NutritionPlan;
