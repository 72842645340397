import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import logo from "../Assets/Images/LogoLDN.png";
import { HashLink } from "react-router-hash-link";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNestedDiseaseDropdownOpen, setIsNestedDiseaseDropdownOpen] =
    useState(false);
  const [isNestedDietDropdownOpen, setIsNestedDietDropdownOpen] =
    useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close the menu when a link is clicked
    setIsDropdownOpen(false); // Close any dropdowns when a link is clicked
    setIsNestedDiseaseDropdownOpen(false); // Close nested disease dropdown
    setIsNestedDietDropdownOpen(false); // Close nested diet dropdown
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleNestedDiseaseDropdown = () => {
    setIsNestedDiseaseDropdownOpen(!isNestedDiseaseDropdownOpen);
  };

  const toggleNestedDietDropdown = () => {
    setIsNestedDietDropdownOpen(!isNestedDietDropdownOpen);
  };

  return (
    <>
      <div className="nav_container">
        <nav className="navbar">
          <div className="logo-menu">
            <div className="logo">
              <div className="logo-icon">
                <img src={logo} alt="letsdietnowlogo" />
              </div>
            </div>

            <div className="hamburger" onClick={toggleMenu}>
              {isOpen ? (
                <AiOutlineClose size={30} />
              ) : (
                <AiOutlineMenu size={30} />
              )}
            </div>
          </div>

          <ul className={`nav-link ${isOpen ? "active" : ""}`}>
            <li>
              <Link to="/" onClick={handleLinkClick}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about-us" onClick={handleLinkClick}>
                About Me
              </Link>
            </li>
            <li className="services-dropdown">
              <div onClick={toggleDropdown}>Services &#9660;</div>
              <ul className={`dropdown ${isDropdownOpen ? "open" : ""}`}>
                <li>
                  <Link
                    to="/services?service=weight-management"
                    onClick={handleLinkClick}
                  >
                    Weight Management
                  </Link>
                </li>
                <li>
                  <div onClick={toggleNestedDiseaseDropdown}>
                    Disease Management &#9660;
                  </div>
                  <ul
                    className={`nested-dropdown ${
                      isNestedDiseaseDropdownOpen ? "open" : ""
                    }`}
                  >
                    <li>
                      <Link
                        to="/services?service=diabetes"
                        onClick={handleLinkClick}
                      >
                        Type I and Type II Diabetes
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services?service=gestationDiabetes"
                        onClick={handleLinkClick}
                      >
                        Prediabetes
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services?service=chronicKidneyDisease"
                        onClick={handleLinkClick}
                      >
                        Chronic Kidney Failure
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services?service=fattyLiverDisease"
                        onClick={handleLinkClick}
                      >
                        Fatty Liver Disease
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services?service=hyperLipidemia"
                        onClick={handleLinkClick}
                      >
                        Hyperlipidemia
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services?service=hypertension"
                        onClick={handleLinkClick}
                      >
                        Hypertension
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services?service=pcos"
                        onClick={handleLinkClick}
                      >
                        PCOS
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services?service=irritableBowelSyndrome"
                        onClick={handleLinkClick}
                      >
                        Irritable Bowel Syndrome
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/services?service=crohnDisease"
                        onClick={handleLinkClick}
                      >
                        Crohn's Disease
                      </Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <div onClick={toggleNestedDietDropdown}>
                    Special Diet &#9660;
                  </div>
                  <ul
                    className={`nested-dropdown ${
                      isNestedDietDropdownOpen ? "open" : ""
                    }`}
                  >
                    <li>
                      <Link
                        to="/services?service=paediatricNutrition"
                        onClick={handleLinkClick}
                      >
                        Paediatric Nutrition
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services?service=ketogenicDiet"
                        onClick={handleLinkClick}
                      >
                        Ketogenic Diet
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <HashLink smooth to="/#blog-container">
                Blogs
              </HashLink>
            </li>
            <li>
              <Link to="/contact-us" onClick={handleLinkClick}>
                Contact Me
              </Link>
            </li>
            <li>
              <Link to="/letsdietnow-services" onClick={handleLinkClick}>
                Book Now
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default NavBar;
