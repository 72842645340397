import React, { useState } from "react";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaEnvelope, FaPhone, FaPhoneAlt } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { SiWhatsapp } from "react-icons/si";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const dummyEmail = "letsdietnow247@gmail.com";
  const samplePhone = "+919742044422";
  const handleSubmit = (e) => {
    e.preventDefault();

    const subject = "Inquiry from Website";
    const body = `Email: Query: ${query}`;

    window.location.href = `mailto:${dummyEmail}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    setEmail("");
    setQuery("");
    setSubmitted(true);
  };

  const handleSocialClick = (url) => {
    window.open(url, "_blank");
  };
  
  const handleWhatsAppCall = () => {
    const phoneNumber = "+919742044422";
    const message = "Hello, I would like to inquire...";
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="footer">
      <div className="ftr-sb_footer ftr-section_padding">
        <div className="ftr-sb_footer-links">
          <div className="ftr-sb_footer-links-business">
            <div className="ftr-contact-info">
              <h4 style={{ marginBottom: "20px", color: "#decfb2" }}>
                Contact Me
              </h4>

              <a
                href={`mailto:${dummyEmail}`}
                className="footer-link"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div className="icon">
                  <FaEnvelope style={{ color: "#decfb2" }} />
                </div>
                {dummyEmail}
              </a>
              <a
  href={`https://wa.me/${samplePhone}`}
  className="footer-link"
  style={{
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  }}
  target="_blank" // Opens WhatsApp in a new tab or app
  rel="noopener noreferrer"
>
  <SiWhatsapp className="icon" style={{ color: "#decfb2" }} />
  {samplePhone}
</a>
              {/* <a
                href={`tel:${samplePhone}`}
                className="footer-link"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                
                <FaPhoneAlt className="icon" style={{ color: "#decfb2" }} />

                {samplePhone}
              </a> */}
            </div>
          </div>

          <div className="ftr-sb_footer-links-social">
            <h4 style={{ marginBottom: "20px" }}>Join me on</h4>

            {/* <p></p> */}
            <div className="socialmedia">
            <p onClick={() => handleSocialClick("https://www.youtube.com/channel/UCWMK-0coKXVMcYkyr6O5Uww")}>
                
                <FaYoutube />
              </p>
              {/* <p onClick={() => handleSocialClick("https://www.facebook.com/")}>
                <SiMeta />
              </p> */}
              {/* <p onClick={() => handleSocialClick("https://www.facebook.com/")}>
                <FaFacebookF />
                <FaYoutube />
              </p>
              <p
                onClick={() => handleSocialClick("https://www.instagram.com/")}
              >
                <BsInstagram />
              </p> */}
              <p onClick={() => handleSocialClick("https://www.linkedin.com/in/emmany-durairaj-64ab0716/")}>
                <FaLinkedinIn />
              </p>
            </div>
          </div>

          <div className="ftr-sb_footer-query">
            <h4 style={{ marginBottom: "20px" }}>Submit a Query</h4>
            <form onSubmit={handleSubmit} className="query-form">
              <input
                type="email"
                className="query-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your email"
                required
              />
              <textarea
                className="query-textarea"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Enter your query here..."
                rows="4"
                required
              ></textarea>
              <button className="submit-btn" type="submit">
                Submit
              </button>
            </form>
            {submitted && (
              <p className="success-message">Query submitted successfully!</p>
            )}
          </div>
          <div className="ftr-sb_footer-links-resource">
            <h4 style={{ marginBottom: "20px" }}>Quick Links</h4>
            <a href="/about-us" style={{ paddingBottom: "10px" }}>
              About Me
            </a>
            <a href="/services?service=weight-management" style={{ paddingBottom: "10px" }}>
              Services
            </a>
            <a href="/contact-us" style={{ paddingBottom: "20px" }}>
              Contact Us
            </a>
          </div>
        </div>

        <hr className="horizontal-line" />
        <div className="ftr-sb_footer-below">
          <div className="ftr-sb_footer-copyright">
            <p>
              {" "}
              @{new Date().getFullYear()} <span>Let's Diet Now.</span> All
              rights reserved.
            </p>
          </div>
          <div className="ftr-sb_footer-below-links">
            <a href="/terms">
              <div>
                <p>Terms & Conditions</p>
              </div>
            </a>
            <a href="/terms">
              <div>
                <p>Privacy & Policy</p>
              </div>
            </a>
            <a  href="https://www.asievegroup.com/"
              target="_blank"
              rel="noopener noreferrer">
              <div>
                <p>Designed by Asieve</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
