import React, { useState, useEffect } from "react";
import "../Assets/Css/Home.css";
import { Container, Card, CardContent, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Link } from "react-router-dom";
import { GiBullseye } from "react-icons/gi";
import { IoTelescope } from "react-icons/io5";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import DrEmmany from "../Assets/Images/DrEmmany.png";
import { motion } from "framer-motion";
import weightManagementImg from "../Assets/Images/WM.png";
import diseaseManagementImg from "../Assets/Images/DM.png";
import specialDietImg from "../Assets/Images/SD.png";
import CardMedia from "@mui/material/CardMedia";
import { RiDoubleQuotesL } from "react-icons/ri";
import { RiDoubleQuotesR } from "react-icons/ri";
import backgroundImage from "../Assets/Images/backgTop.png";
import dummyImage from "../Assets/Images/dummyImage.jpg";
import blgDia from "../Assets/Images/blog_Dia.jpeg";
import blgliver from "../Assets/Images/blg_liver.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HashLink } from "react-router-hash-link";

const CarouselCards = () => {
  const [isTouched, setIsTouched] = useState(false);

  const handleTouchStart = () => {
    setIsTouched(true);
    setTimeout(() => setIsTouched(false), 3000);
  };

  const blogs = [
    {
      title: "Diabetes overview",
      date: "October 01, 2024",
      keywords: "Blood Sugar, Diet Management, Myths",
      link: "/blogs/diabetes#blog-diabetes",
      image: blgDia,
    },
    {
      title: "The impact of food on our health",
      date: "October 01, 2024",
      keywords: "Nutrition, Balance, Health",
      link: "/blogs/balanced diet#blog-balanced-diet",
      image: dummyImage,
    },
    {
      title: "Non-alcoholic fatty liver disease (NAFLD) ",
      date: "October 01, 2024",
      keywords: "Fatty Liver, Management, Life Style",
      link: "/blogs/non alcholic fatty liver disease#nafld",
      image: blgliver,
    },
  ];

  const NextArrow = ({ onClick }) => (
    <div className="arrow next" onClick={onClick}>
      &gt;
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div className="arrow prev" onClick={onClick}>
      &lt;
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [activeSlide, setActiveSlide] = useState(0);

  const handleSlideChange = (index) => {
    setActiveSlide(index);
  };

  const carouselSettings = {
    autoPlay: true,
    infiniteLoop: true,
    showThumbs: false,
    showIndicators: true,
    showStatus: false,
    interval: 5000,
    swipeable: false,
    onChange: handleSlideChange,
  };

  return (
    <>
      <Carousel
        {...carouselSettings}
        selectedItem={activeSlide}
        onChange={(index) => setActiveSlide(index)}
      >
        <div className="home-carousel-slide">
          <div className="home-carousel-background">
            <img
              src={DrEmmany}
              alt="DrEmmany"
              className="home-dr-emmany-image"
            />
            {activeSlide === 0 && (
              <motion.div
                className="home-text-slide1"
                initial={{ opacity: 0, y: 50, scale: 0.8 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                transition={{ duration: 4.5, ease: "easeInOut" }}
                style={{ color: "#decfb2" }}
              >
                <motion.h2
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.5 }}
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2.5rem",
                    lineHeight: "1.2",
                    letterSpacing: "0.05em",
                    color: "#fff",
                  }}
                >
                  Welcome to Let's Diet Now!
                </motion.h2>
                <motion.p
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.5 }}
                  style={{
                    letterSpacing: "0.04em",
                    fontSize: "1.5rem",
                    lineHeight: "1.6",
                    color: "#decfb2",
                  }}
                >
                  Eating what you like is happiness, but what you eat tells
                  who you are!!
                </motion.p>
              </motion.div>
            )}
          </div>
        </div>

        <div className="home-carousel-slide">
          <div className="home-carousel-background">
            <img
              src={DrEmmany}
              alt="DrEmmany"
              className="home-dr-emmany-image"
            />
            {activeSlide === 1 && (
              <motion.div
                className="home-text-slide1"
                initial={{ opacity: 0, y: 50, scale: 0.8 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                transition={{ duration: 4.5, ease: "easeInOut" }}
                style={{ color: "#decfb2" }}
              >
                <motion.h2
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.5 }}
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2rem",
                    lineHeight: "1.2",
                    letterSpacing: "0.05em",
                    color: "#decfb2",
                  }}
                >
                  Eat Right but don’t starve, when it comes to Diet, it’s not
                  hard
                </motion.h2>
              </motion.div>
            )}
          </div>
        </div>

        <div className="home-carousel-slide">
          <div className="home-carousel-background">
            <img
              src={DrEmmany}
              alt="DrEmmany"
              className="home-dr-emmany-image"
            />
            {activeSlide === 2 && (
              <motion.div
                className="home-text-slide1"
                initial={{ opacity: 0, y: 50, scale: 0.8 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                transition={{ duration: 4.5, ease: "easeInOut" }}
                style={{ color: "#decfb2" }}
              >
                <motion.h2
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.5 }}
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2rem",
                    lineHeight: "1.2",
                    letterSpacing: "0.05em",
                    color: "#decfb2",
                  }}
                >
                  Let food Be Thy medicine and medicine be thy food -
                  Hippocrates
                </motion.h2>
              </motion.div>
            )}
          </div>
        </div>
      </Carousel>

      <div
        className="home-about-me"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        <h3>
          <span>
            <RiDoubleQuotesL className="quote-icon" />
            Let's Diet Now is an online nutrition consultancy platform where you
            can connect with me for customized meal plan and nutritional
            guidance for various disease conditions.
            <RiDoubleQuotesR className="quote-icon" />
          </span>
        </h3>
      </div>

      <div
        className="mission-vision"
        style={{ marginTop: "20px", paddingRight: "10px", paddingLeft: "10Px" }}
      >
        <Grid container spacing={4} direction="row">
          <Grid size={{ xs: 12, sm: 6, md: 6 }}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <IoTelescope
                    size={80}
                    color="#325836"
                    style={{ marginRight: "px" }}
                  />
                  <Typography
                    variant="h4"
                    component="h4"
                    sx={{
                      color: "#325836",
                      paddingLeft: "50px",
                      fontFamily: "'Helvetica', sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    Vision
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "10px",
                    fontSize: "1.5rem",
                    fontFamily: "'Helvetica', sans-serif",
                    fontWeight: "medium",
                    padding: "0px 20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    lineHeight: 1.5,
                    letterSpacing: "0.05em",
                    color: "#385537",
                  }}
                >
                  Empowering people with nutritional knowledge to make wise food
                  choices and improve their quality of life.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 6 }}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <GiBullseye
                    size={80}
                    color="#325836"
                    style={{
                      marginRight: "-40px",
                      transform: "rotate(45deg)",
                    }}
                  />
                  <Typography
                    variant="h4"
                    component="h4"
                    sx={{
                      color: "#325836",
                      paddingLeft: "50px",
                      fontFamily: "'Helvetica', sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    Mission
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: "10px",
                    fontSize: "1.5rem",
                    fontFamily: "'Helvetica', sans-serif",
                    fontWeight: "medium",
                    padding: "0px 20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    lineHeight: 1.5,
                    letterSpacing: "0.05em",
                    color: "#385537",
                  }}
                >
                  To provide evidence-based diet plans and to prevent and manage
                  disease conditions.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          marginTop: "20px",
          backgroundColor: "#385537",
          textAlign: "center",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <h3
          style={{
            backgroundColor: "#385537",
            color: "#FFFFFF",
            margin: 0,
            paddingTop: "20px",
            paddingBottom: "20px",
            fontSize: "35px",
          }}
        >
          Services
        </h3>
      </div>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        padding="20px"
        sx={{ gap: "10px" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Link
            to="/services?service=weight-management"
            style={{ textDecoration: "none" }}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                "&:hover": { boxShadow: 6 },
              }}
              className={`custom-card ${isTouched ? "touched" : ""}`}
              onTouchStart={handleTouchStart}
            >
              <CardMedia
                component="img"
                className="responsive-image"
                image={weightManagementImg}
                alt="Special Diet"
                sx={{
                  width: "100%",
                  height: "auto",
                }}
              />
              <button className="hover-button">Click Me</button>
            </Card>
          </Link>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Link
            to="/services?service=diabetes"
            style={{ textDecoration: "none" }}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                "&:hover": { boxShadow: 6 },
              }}
              className={`custom-card ${isTouched ? "touched" : ""}`}
              onTouchStart={handleTouchStart}
            >
              <CardMedia
                component="img"
                className="responsive-image"
                image={diseaseManagementImg}
                alt="Special Diet"
                sx={{
                  width: "100%",
                  height: "auto",
                }}
              />
              <button className="hover-button">Click Me</button>
            </Card>
          </Link>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Link
            to="/services?service=paediatricNutrition"
            style={{ textDecoration: "none" }}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                "&:hover": { boxShadow: 6 },
              }}
              className={`custom-card ${isTouched ? "touched" : ""}`}
              onTouchStart={handleTouchStart}
            >
              <CardMedia
                component="img"
                className="responsive-image"
                image={specialDietImg}
                alt="Special Diet"
                sx={{
                  width: "100%",
                  height: "auto",
                }}
              />
              <button className="hover-button">Click Me</button>
            </Card>
          </Link>
        </Grid>
      </Grid>

      <div className="blog-container" id="blog-container">
        <h1 style={{ fontSize: "30px" }}>Explorations & Enlightenments</h1>
        <Slider {...settings}>
          {blogs.map((blog, index) => (
            <div key={index} className="blog-content">
              <img src={blog.image} alt={blog.title} />
              <h3 style={{ textTransform: "capitalize", color: "#33587" }}>
                {blog.title}
              </h3>
              <p>{blog.keywords}</p>
              {/* <p>{blog.date}</p> */}
              <p>
                <HashLink smooth to={blog.link}>
                  Know More ...
                </HashLink>
              </p>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default CarouselCards;
