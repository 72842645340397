import React from "react";


const Term = () => {
  return (
    <div className="Term">
   <h2> Terms and conditions</h2>

<h4>Welcome to Let’s Diet Now.com</h4>

<p>These terms and conditions outline the rules and regulations for the use of our website. By accessing our website and services, you accept and agree to these Terms and Conditions. If you do not agree with any part of these terms, please do not use our site.
The following terminology applies to these Terms and Conditions, Privacy Statement, Disclaimer Notice and any or all Agreements Throughout the site, the terms “I” “we”, “us” and “our” refer to Lets Diet Now.com.: “Client,” “You,” and “Your” refers to you, the person accessing this website and accepting the terms and conditions
The content of this website is for general information and educational purposes only. It is not intended to be a substitute for professional medical advice, diagnosis, or treatment
</p>
<h4>Use of website</h4>
<p>You agree to use our website in accordance with all applicable laws and regulations. You are prohibited from using our site for any unlawful or unauthorized purpose. You must not engage in any activity that disrupts or interferes with the functionality of our site.
</p>
<h4>Data Loss</h4>
The Owner does not accept responsibility for the security of Your account or content. You agree that Your use of the Website or Services is at Your own risk.

<h4>Payment for Services</h4>
We charge a fee per consultation. The payment for the services is made through the available payment methods through the payment gateway. Let’s Diet Now.com reserves the right to change the fees charged for any of the services at any time without prior notice.
Use of our services
You shall provide your accurate personal data i.e. Name, Age, address, mobile number, height, weight, when asked for. You shall be responsible for storing your login credentials safely so as to prevent misuse of your account. You shall not share any other person’s personal information.

<h4>Changes to Terms</h4>

<p>Let’s Diet Now.com reserves the right to modify these Terms and Conditions at any time. You should visit this page from time to time to review the Terms because they are legally binding on you. We may modify or discontinue any features, services, tools, content at any time, with or without notice, and without liability.
For any questions or concerns regarding these terms and conditions, please contact us through the contact information provided on the website.
</p>
</div>


  );
}

export default  Term;