import React, { useState, useEffect } from "react";
import "../Assets/Css/Services.css";
import WeightMan from "../Assets/Images/WideIMG.webp";
import kidney from "../Assets/Images/Serv_Kidney.webp";
import Irritable from "../Assets/Images/Serv_Syndrome.webp";
import gestationalDiabetes from "../Assets/Images/Serv_gesD.webp";
import diabetes from "../Assets/Images/Serv_Dia.webp";
import hyper from "../Assets/Images/Serv_hyperten.webp";
import Hyperlipidaemia from "../Assets/Images/Serv_hyper.webp";
import Paediatric from "../Assets/Images/Serv_Ped.webp";
import Ketogenic from "../Assets/Images/Serv_Keto.webp";
import Poly from "../Assets/Images/Serv_Pcos.webp";
import fattyLiver from "../Assets/Images/Serv_Liver.webp";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Services = () => {
  const [isDiseaseExpanded, setIsDiseaseExpanded] = useState(false);
  const [isDietExpanded, setIsDietExpanded] = useState(false);

  const toggleDisease = () => setIsDiseaseExpanded(!isDiseaseExpanded);
  const toggleDiet = () => setIsDietExpanded(!isDietExpanded);

  const { hash } = useLocation();

  const location = useLocation();

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return params.get("service");
  };

  const activeService = getQueryParams();

  const serviceHeadings = {
    "weight-management": "Weight Management",
    diabetes: "Diabetes",
    gestationDiabetes: "Pre-Diabetes",
    chronicKidneyDisease: "Chronic Kidney Disease",
    fattyLiverDisease: "Fatty Liver Disease",
    hyperLipidemia: "Hyperlipidemia",
    hypertension: "Hypertension",
    irritableBowelSyndrome: "Irritable Bowel Syndrome",
    crohnDisease: "Crohn's Disease",
    pcos: "PCOS",
    paediatricNutrition: "Paediatric Nutrition",
    ketogenicDiet: "Ketogenic Diet",
    cancerDiet: "Diet for Cancer Treatment",
  };

  const navigate = useNavigate();

  const handleConsultClick = (service) => {
    navigate(`/letsdietnow-services?service=${service}`);
  };

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <>
      <div className="service-container">
        <div className="service-sidebar">
          <div className="service-sidebar-list">
            <ul>
              <li>
                <h5>Services</h5>

                <li>
                  <Link to="/services?service=weight-management">
                    Weight Management
                  </Link>
                </li>
                <li>
                  <div className="dropdown-toggle" onClick={toggleDisease}>
                    Disease Management{" "}
                    {isDiseaseExpanded ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                  {isDiseaseExpanded && (
                    <ul className="dropd" style={{ padding: "0 25px" }}>
                      <li>
                        <Link to="/services?service=diabetes">
                          Type I & Type II Diabetes
                        </Link>
                      </li>
                      <li>
                        <Link to="/services?service=gestationDiabetes">
                          Prediabetes
                        </Link>
                      </li>
                      <li>
                        <Link to="/services?service=chronicKidneyDisease">
                          Chronic Kidney Disease
                        </Link>
                      </li>
                      <li>
                        <Link to="/services?service=fattyLiverDisease">
                          Fatty Liver Disease
                        </Link>
                      </li>
                      <li>
                        <Link to="/services?service=hyperLipidemia">
                          Hyperlipidemia
                        </Link>
                      </li>
                      <li>
                        <Link to="/services?service=hypertension">
                          Hypertension
                        </Link>
                      </li>
                      <li>
                        <Link to="/services?service=pcos">
                          Polycystic Ovary Syndrome (PCOS)
                        </Link>
                      </li>
                      <li>
                        <Link to="/services?service=irritableBowelSyndrome">
                          Irritable Bowel Syndrome (IBS)
                        </Link>
                      </li>
                      {/* <li>
                          <Link to="/services?service=crohnDisease">
                            Crohn’s Disease
                          </Link>
                        </li> */}
                    </ul>
                  )}
                </li>

                <li>
                  <div className="dropdown-toggle" onClick={toggleDiet}>
                    Special Diet{" "}
                    {isDietExpanded ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                  {isDietExpanded && (
                    <ul className="dropd" style={{ padding: "0 25px" }}>
                      <li>
                        <Link to="/services?service=paediatricNutrition">
                          Paediatric Diet
                        </Link>
                      </li>
                      <li>
                        <Link to="/services?service=ketogenicDiet">
                          Ketogenic Diet
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              </li>
            </ul>
          </div>
        </div>
        <div className="service-content" style={{ padding: "25px 25px" }}>
          {activeService === "weight-management" && (
            <div className="weight_management" id="weight-management">
              <div className="services-offered-content-wrapper">
                <h2>{serviceHeadings[activeService] || "Services Heading"}</h2>
                <img
                  src={WeightMan}
                  loading="lazy"
                  style={{
                    width: "70%", // Responsive width
                    //maxWidth: '400px',  // Max width for larger screens
                    height: "70%", // Maintain aspect ratio
                    margin: "20px auto", // Center the image with margin
                    //display: 'block',  // Ensure the image is centered
                  }}
                  alt="Weight Management"
                />

                <p>
                  You want to drop weight and want to do it safely? “Lose weight
                  the right way”. Weight management refers to the process of
                  achieving and maintaining a healthy weight through a
                  combination of balanced diet, exercise, and lifestyle changes.
                  It’s important to focus on progress, not perfection. We focus
                  on a customized diet plan to help you achieve and maintain a
                  healthy weight.
                </p>
                <p style={{ display: "flex", justifyContent: "flex-start" }}>
                  <b>Price :</b> Rs.1,000
                </p>
                <button onClick={() => handleConsultClick("weight-management")}>
                  Consult Dietitian
                </button>
              </div>
            </div>
          )}

          {activeService === "diabetes" && (
            <div className="weight_management" id="diabetes">
              <div className="services-offered-content-wrapper">
                <h2>{serviceHeadings[activeService] || "Services Heading"}</h2>
                <img
                  src={diabetes}
                  loading="lazy"
                  style={{
                    width: "70%",
                    height: "70%",
                    margin: "20px auto",
                  }}
                  alt="Diabetes Management"
                />

                <p>
                  <b>Type I & Type II Diabetes:</b> Managing diabetes doesn’t
                  have to be complicated, especially with convenient and
                  accessible nutrition counselling, “Get the personalized diet
                  plans you need and manage your blood sugar levels.”
                </p>
                {/* <p>
                  <b>Pre-Diabetes:</b> Pre diabetes is a warning of Type 2
                  Diabetes. It means blood sugar levels are higher than normal
                  but not high enough to be considered Type 2 diabetes. Adopting
                  a healthier diet, regular exercise and maintaining a healthy
                  weight can reverse Pre diabetes. Get a personalized diet plan
                  and delay progression to Type 2 diabetes.
                </p> */}
                <p>
                  <b>Price :</b> Rs.1,000
                </p>
                <button onClick={() => handleConsultClick("diabetes")}>
                  Consult Dietitian
                </button>
              </div>
            </div>
          )}

          {activeService === "gestationDiabetes" && (
            <div className="chronic-kidney" id="gestationDiabetes">
              <div className="services-offered-content-wrapper">
                <h2>{serviceHeadings[activeService] || "Services Heading"}</h2>
                <img
                  src={gestationalDiabetes}
                  loading="lazy"
                  style={{
                    width: "70%",
                    height: "70%",
                    margin: "20px auto",
                  }}
                  alt="chronic-kidney-failure"
                />

                <p>
                  {" "}
                  Pre diabetes is a warning of Type 2 Diabetes. It means blood
                  sugar levels are higher than normal but not high enough to be
                  considered Type 2 diabetes. Adopting a healthier diet, regular
                  exercise and maintaining a healthy weight can reverse Pre
                  diabetes. Get a personalized diet plan and delay progression
                  to Type 2 diabetes.
                </p>
                <p>
                  <b>Price :</b> Rs.1,000
                </p>
                <button onClick={() => handleConsultClick("gestationDiabetes")}>
                  Consult Dietitian
                </button>
              </div>
            </div>
          )}

          {activeService === "chronicKidneyDisease" && (
            <div className="chronic-kidney" id="chronicKidneyDisease">
              <div className="services-offered-content-wrapper">
                <h2>{serviceHeadings[activeService] || "Services Heading"}</h2>
                <img
                  src={kidney}
                  loading="lazy"
                  style={{
                    width: "70%",
                    height: "70%",
                    margin: "20px auto",
                  }}
                  alt="chronic-kidney-failure"
                />
                <p>
                  When you have kidney problems, Diet becomes more important
                  than ever. Our kidney removes toxins from our bodies. When you
                  have chronic kidney disease, your body’s ability to remove
                  waste is compromised. Making conscious food choices and having
                  a well-balanced diet becomes an important part in managing
                  your disease progression. “Eat right for chronic kidney
                  disease”. Get personalized diet plan and delay progression of
                  chronic kidney disease
                </p>
                <p>
                  <b>Price :</b> Rs.1,000
                </p>
                <button
                  onClick={() => handleConsultClick("chronicKidneyDisease")}
                >
                  Consult Dietitian
                </button>
              </div>
            </div>
          )}

          {activeService === "fattyLiverDisease" && (
            <div className="chronic-kidney" id="fattyLiverDisease">
              <div className="services-offered-content-wrapper">
                <h2>{serviceHeadings[activeService] || "Services Heading"}</h2>
                <img
                  src={fattyLiver}
                  loading="lazy"
                  style={{
                    width: "70%",
                    height: "70%",
                    margin: "20px auto",
                  }}
                  alt="chronic-kidney-failure"
                />
                <p>
                  Fatty liver disease is a condition where the liver cells
                  become filled with fat. This fat can cause injury to the liver
                  which can permanently scarred and damage the liver. You can
                  control the progression of fatty liver by making the choice to
                  change your diet. “A Healthy Diet, a Healthier liver”
                </p>
                <p>
                  <b>Price :</b> Rs.1,000
                </p>
                <button onClick={() => handleConsultClick("fattyLiverDisease")}>
                  Consult Dietitian
                </button>
              </div>
            </div>
          )}

          {activeService === "hyperLipidemia" && (
            <div className="chronic-kidney" id="hyperLipidemia">
              <div className="services-offered-content-wrapper">
                <h2>{serviceHeadings[activeService] || "Services Heading"}</h2>
                <img
                  src={Hyperlipidaemia}
                  loading="lazy"
                  style={{
                    width: "70%",
                    height: "70%",
                    margin: "20px auto",
                  }}
                  alt="chronic-kidney-failure"
                />
                <p>
                  What you eat can significantly affect the cholesterol
                  circulating in your blood. Individuals with high cholesterol
                  face twice the risk of developing heart disease, the leading
                  cause of death. Take control of your heart health today and
                  embark on a journey toward a healthier, happier you! Learn how
                  to improve your heart health and lower your cholesterol by
                  following our customized diet plan.
                </p>
                <p>
                  <b>Price :</b> Rs.1,000
                </p>
                <button onClick={() => handleConsultClick("hyperLipidemia")}>
                  Consult Dietitian
                </button>
              </div>
            </div>
          )}

          {activeService === "hypertension" && (
            <div className="chronic-kidney" id="hypertension">
              <div className="services-offered-content-wrapper">
                <h2>{serviceHeadings[activeService] || "Services Heading"}</h2>
                <img
                  src={hyper}
                  loading="lazy"
                  style={{
                    width: "70%",
                    height: "70%",
                    margin: "20px auto",
                  }}
                  alt="chronic-kidney-failure"
                />
                <p>
                  High blood pressure is often called the “silent killer”
                  because it can go undetected for years. Eating a heart-healthy
                  diet is important for managing your blood pressure and
                  reducing the risk of heart attack, stroke, and other health
                  threats. Making changes to your diet is a proven way to help
                  control high blood pressure. We focus on a quality diet plan
                  to help you manage your condition effectively.
                </p>
                <p>
                  <b>Price :</b> Rs.1,000
                </p>
                <button onClick={() => handleConsultClick("hypertension")}>
                  Consult Dietitian
                </button>
              </div>
            </div>
          )}

          {activeService === "irritableBowelSyndrome" && (
            <div className="chronic-kidney" id="irritableBowelSyndrome">
              <div className="services-offered-content-wrapper">
                <h2>{serviceHeadings[activeService] || "Services Heading"}</h2>
                <img
                  src={Irritable}
                  loading="lazy"
                  style={{
                    width: "70%",
                    height: "70%",
                    margin: "20px auto",
                  }}
                  alt="chronic-kidney-failure"
                />
                <p>
                  Irritable Bowel Syndrome (IBS) is a common gastrointestinal
                  disorder characterized by symptoms like bloating, abdominal
                  pain, and irregular bowel movements (diarrhea and/or
                  constipation). Managing IBS often involves avoiding foods that
                  can trigger the symptoms. To effectively manage your IBS,
                  consider contacting “LetsDietNow” with a doctor's referral for
                  tailored meal plan to relieve your IBS symptoms.
                </p>
                <p>
                  <b>Price :</b> Rs.1,000
                </p>
                <button
                  onClick={() => handleConsultClick("irritableBowelSyndrome")}
                >
                  Consult Dietitian
                </button>
              </div>
            </div>
          )}

          {/* {activeService === "crohnDisease" && (
            <div className="chronic-kidney" id="crohnDisease">
              <div className="services-offered-content-wrapper">
                <h2>{serviceHeadings[activeService] || "Services Heading"}</h2>
                <img src={dummyImage} alt="chronic-kidney-failure" />
                <p>this is sample content.</p>
                <p>
                  <b>Price :</b> Rs.1,000
                </p>
                <button onClick={() => handleConsultClick("crohnDisease")}>
                  Consult Dietitian
                </button>
              </div>
            </div>
          )} */}

          {activeService === "pcos" && (
            <div className="chronic-kidney" id="pcos">
              <div className="services-offered-content-wrapper">
                <h2>{serviceHeadings[activeService] || "Services Heading"}</h2>
                <img
                  src={Poly}
                  loading="lazy"
                  style={{
                    width: "70%",
                    height: "70%",
                    margin: "20px auto",
                  }}
                  alt="chronic-kidney-failure"
                />
                <p>
                  Polycystic ovary syndrome (PCOS) is a common complex hormonal
                  condition which affects how the ovaries work and it affects up
                  to 20 per cent of women worldwide, yet the exact cause is
                  still unknown. Studies show that what you eat, along with
                  daily physical activity, makes a significant impact on
                  managing symptoms. We focus on balanced diet plan to help&
                  improve PCOS symptoms.
                </p>
                <p>
                  <b>Price :</b> Rs.1,000
                </p>
                <button onClick={() => handleConsultClick("pcos")}>
                  Consult Dietitian
                </button>
              </div>
            </div>
          )}

          {activeService === "paediatricNutrition" && (
            <div className="chronic-kidney" id="paediatricNutrition">
              <div className="services-offered-content-wrapper">
                <h2>{serviceHeadings[activeService] || "Services Heading"}</h2>
                <img
                  src={Paediatric}
                  loading="lazy"
                  style={{
                    width: "70%",
                    height: "70%",
                    margin: "20px auto",
                  }}
                  alt="chronic-kidney-failure"
                />
                <p>
                  <b>Child Nutrition:</b>A balanced diet is important for
                  children’s growth and development. Children are constantly
                  growing and need the right food to maintain a healthy body
                  weight and improve their immunity. Developing healthy eating
                  habits early on can lead to better physical and cognitive
                  health as they grow. It’s important to create a positive
                  relationship with food. We provide customized diet plans for
                  childhood obesity and other health conditions.
                </p>
                <p>
                  <b>Price :</b> Rs.1,000
                </p>
                <button
                  onClick={() => handleConsultClick("paediatricNutrition")}
                >
                  Consult Dietitian
                </button>
              </div>
            </div>
          )}

          {activeService === "ketogenicDiet" && (
            <div className="chronic-kidney" id="ketogenicDiet">
              <div className="services-offered-content-wrapper">
                <h2>{serviceHeadings[activeService] || "Services Heading"}</h2>
                <img
                  src={Ketogenic}
                  loading="lazy"
                  style={{
                    width: "70%",
                    height: "70%",
                    margin: "20px auto",
                  }}
                  alt="chronic-kidney-failure"
                />
                <p>
                  <b>Ketogenic Diet:</b>
                  Ketogenic Diet is often prescribed for Epilepsy patients,
                  particularly for those who do not respond well to conventional
                  treatment. It’s a high fat, low carbohydrate diet. As it
                  requires careful planning to ensure nutritional balance and to
                  monitor for any potential side effects. It’s available only
                  through a doctor’s referral.
                </p>
                <p>
                  <b>Price :</b> Rs.1500
                </p>
                <button onClick={() => handleConsultClick("ketogenicDiet")}>
                  Consult Dietitian
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Services;
