import React from "react";
import { Link } from "react-router-dom";
import "../../Assets/Css/Blogs.css";
import diseaseManagement from "../../Assets/Images/blog_Dia.jpeg";
import { Height } from "@mui/icons-material";
import { RiH4 } from "react-icons/ri";

const BlogTemplate = () => {
  return (
    <div className="blog-template clearfix">
      <header className="blog-header">
        <h1
          style={{ textTransform: "capitalize" }}
          className="blog-title"
          id="blog-diabetes"
        >
          Diabetes Overview
        </h1>
        {/* <p className="blog-date">October 01, 2024</p> */}
        <p className="blog-keywords">Nutrition, Health, Wellness</p>
      </header>

      <div className="blog-content">
        <img
          src={diseaseManagement}
          alt="Nutrition-related"
          className="blog-image"
        />
        <section className="diabetes-overview">
          {/* <h2></h2> */}
          <h2>What is Diabetes?</h2>
          <p>
            Diabetes is a chronic health condition characterized by high blood
            sugar (glucose) levels due to issues with insulin production,
            insulin action, or both. Insulin is a hormone produced by the
            pancreas (an organ behind the stomach) that helps regulate blood
            sugar by allowing glucose from food to enter cells for energy.
          </p>
          <p>
            In diabetes, the body either doesn't produce enough insulin (Type 1
            Diabetes) or doesn't use insulin effectively (Type 2 Diabetes), or
            the body doesn't respond appropriately to insulin, a condition
            called "insulin resistance." This leads to elevated blood glucose
            levels, which can cause various health issues over time. Managing
            diabetes often involves monitoring blood sugar levels, making
            dietary changes, and using medication or insulin therapy.
          </p>

          <h2>Symptoms of Diabetes</h2>
          <ul>
            <li>Increased thirst</li>
            <li>Frequent urination</li>
            <li>Extreme hunger</li>
            <li>Unexplained weight loss</li>
            <li>Fatigue (weak, tired feeling)</li>
            <li>Blurred vision</li>
            <li>Slow-healing sores</li>
            <li>
              Frequent infections, such as gums or skin infections and vaginal
              infections
            </li>
          </ul>

          <h2>What Causes Diabetes?</h2>
          <p>
            <strong>Type 1 Diabetes:</strong> It results from the pancreas'
            failure to produce enough insulin due to the loss of beta cells. The
            immune system mistakenly attacks and destroys insulin-producing beta
            cells in the pancreas. The cause of this autoimmune response is
            unknown, but genetic and environmental factors may play a role.
          </p>
          <p>
            <strong>Type 2 Diabetes:</strong>
          </p>
          <ul>
            <li>Being physically inactive</li>
            <li>Having excess weight or obesity</li>
            <li>Having a family history of type 2 diabetes</li>
            <li>Having pre-diabetes</li>
          </ul>

          <h2>Diet Management of Diabetes</h2>
          <ul>
            <li>Eat a wide variety of foods</li>
            <li>Maintain a healthy weight</li>
            <li>
              Choose foods high in fiber such as whole grain cereals, millets
            </li>
            <li>
              Aim for 3 major meals and 2 portions of low-carb snacks a day
            </li>
            <li>Meals should be eaten at regular times</li>
            <li>
              Nuts like peanuts, walnuts, and almonds are good sources of fiber
              & healthy fat, but watch portion sizes
            </li>
            <li>Be a carb detective—read food labels</li>
            <li>Watch your portions</li>
            <li>Exercise and lifestyle modification are key</li>
          </ul>

          <h2>Common Food Myths about Diabetes</h2>
          <p>
            Many myths surround diabetes, especially when it comes to food and
            diabetes. Are carbs bad for you? Are you allowed to eat sweets? Do
            you have to follow a special diet? This makes it hard to figure out
            what you can and cannot eat. But there is no such thing as a
            diabetic diet. The fact is people with diabetes are advised to eat
            a healthy, balanced diet, just like everyone else.{" "}
          </p>
          <p>
            It’s crucial to debunk myths surrounding food and diabetes to
            promote better understanding and management. Here are some common
            myths and the facts behind them:{" "}
          </p>
          <h4>MYTH I: Diabetic can take honey & Jaggery instead of sugar</h4>
          <p>
            <strong>FACT:</strong> Generally, there is no advantage to
            substituting honey for sugar. Both honey and sugar will affect the
            blood sugar levels. Honey, Jaggery & sugar are equal in calories.
          </p>

          <h4>MYTH II: People with Diabetes should not eat rice & potatoes</h4>
          <p>
            <strong>FACT:</strong> Starchy foods can be a part of healthy meal
            plan. Cereals, pasta, rice and starchy vegetables like potatoes can
            be included in their meals but only in moderate amount. The focus
            should be on portion control, choosing whole grain options when
            possible, and balancing them with vegetables and protein sources.
            This approach helps manage blood sugar levels effectively while
            still enjoying a variety of foods..
          </p>

          <h4>
            MYTH III: Carbohydrates should be restricted for people with
            Diabetes
          </h4>
          <p>
            <strong>FACT:</strong> The focus should be on choosing healthy,
            complex carbohydrates—like whole grains, whole pulses and
            vegetables—rather than completely eliminating carbs. It’s about
            managing portion sizes and the overall balance of nutrients.
            Consulting with a healthcare provider or a registered dietitian can
            help create a personalized meal plan that works best.
          </p>

          <h4>
            MYTH IV: People with Diabetes should not eat fruits as they are
            sweet
          </h4>
          <p>
            <strong>FACT:</strong> Fruits provide essential nutrients, fiber,
            and vitamins that are beneficial for overall health. While it's
            important to be mindful of portion sizes and choose lower glycemic
            index (GI) options, there are no "forbidden" fruits. A daily serving
            of 100-150 grams of low GI fruits can be part of a balanced diet.
            It's best to avoid fruit juices and drinks, as they can contain
            added sugars and lack the fiber found in whole fruits. Enjoying a
            variety of fruits in moderation can be a healthy choice for those
            with diabetes!
          </p>

          <h4>MYTH V: People with Diabetes need to follow a special diet</h4>
          <p>
            <strong>FACT:</strong> A healthy meal plan for people with diabetes
            is generally the same as a healthy diet for anyone. People with
            diabetes can eat the same foods the family enjoys. Everyone benefits
            from healthy eating so the whole family can take part in healthy
            eating.
          </p>
        </section>
        <section className="conclusion">
          <h2>Conclusion</h2>
          <p>
            Adopting a balanced diet is a key step towards achieving a healthier
            lifestyle. Consider consulting a nutritionist for personalized
            advice tailored to your needs.
          </p>
        </section>
      </div>
    </div>
  );
};

export default BlogTemplate;
